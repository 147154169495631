/* eslint-disable no-unused-expressions */
import throttle from 'lodash/throttle'
import API from './api'
import {
  getCookie,
  setCookie,
  animate,
  getOffset,
  scrollTo,
  generateAffiliateUrl,
  selectAffiliate,
  isMobile,
  isStaff,
  parseQuery,
} from './utils'
import {
  openSalesForcePopup,
  trackClick,
} from './components/dive-guides/gtm-events'
import messages from './messages'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'
import PriceConverter from './components/helpers/PriceConverter'
import store from 'store2'
import get from 'lodash/get'
import CommentTooltip from './components/adventures/CommentTooltip'
import DjangoModal from './components/helpers/DjangoModal'
import { AdventureOriginalTitle } from './components/adventures'
import PicPreview from './components/liveaboard/PicPreview'

export function headerDirective() {
  let items = document.querySelectorAll('[mobile-hover]')
  let mobileExpandableItems = document.querySelectorAll('[expandable-item]')
  let delay = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )
    ? 0
    : 500

  items.forEach(function(item) {
    let timer, leaveTimer

    item.addEventListener('mouseenter', function() {
      clearTimeout(leaveTimer)
      timer = setTimeout(function() {
        item.classList.add('active')
      }, delay)
    })
    item.addEventListener('mouseleave', function() {
      clearTimeout(timer)
      leaveTimer = setTimeout(function() {
        item.classList.remove('active')
      }, delay)
    })
  })

  mobileExpandableItems.forEach(function(item) {
    item.addEventListener('click', function() {
      this.classList.toggle('active')
    })
  })
}

export function convertDjangoRenderedPrice(renderElement, conversionPercent) {
  let prices = document.querySelectorAll('[data-price-block]')
  if(prices) {
    prices.forEach(function(item) {
      let currency = item.dataset.shopCurrency
      let price = item.dataset.price
      renderElement(item, PriceConverter, {
        amount: +price,
        currency,
        conversionPercent,
      })
    })
  }
}

export function commentTooltip(renderElement) {
  let items = document.querySelectorAll('[data-tooltip]')
  if(items) {
    items.forEach(function(item) {
      let comment = item.dataset.tooltip
      let icon = item.dataset.icon

      if(comment) {
        renderElement(item, CommentTooltip, { comment, icon })
      }
    })
  }
}

export function originalTitleTooltip(renderElement) {
  let items = document.querySelectorAll('[data-original-title]')
  if(items) {
    items.forEach(function(item) {
      let title = item.dataset.text
      if(title) {
        renderElement(item, AdventureOriginalTitle, { originalTitle: title })
      }
    })
  }
}

export function showModalOnClick(renderElement) {
  let items = document.querySelectorAll('[data-modal-text]')
  if(items) {
    items.forEach(function(item) {
      let comment = item.dataset.modalText
      let text = item.innerText

      if(comment) {
        renderElement(item, DjangoModal, { comment, text })
      }
    })
  }
}

export function currencyDirective(callback) {
  let items = document.querySelectorAll('.currency-item')
  let displayedCurrency = document.getElementById('selected-currency')
  let mobileCurrency = document.getElementById('currency-select')
  let selectedCurrency = window.currency || getCookie('currency_code')
  changeCurrency()

  onClick('.currency-item', function(item) {
    selectedCurrency = item.dataset.currencyTitle
    changeCurrency()
    mobileCurrency.value = selectedCurrency
  })

  if(mobileCurrency) {
    mobileCurrency.onchange = function() {
      selectedCurrency = mobileCurrency.value
      changeCurrency()
    }
  }

  function changeCurrency() {
    setCookie('currency_code', selectedCurrency, {
      path: '/',
      domain:
        process.env.ENVIRONMENT === 'production'
          ? 'travel.padi.com'
          : window.location.hostname,
    })

    items.forEach(function(item) {
      if(selectedCurrency !== item.dataset.currencyTitle) {
        item.classList.remove('selected')
      } else {
        item.classList.add('selected')
      }
    })

    if(callback) {
      callback(selectedCurrency)
    }

    document.querySelectorAll('.eur').forEach((item) => {
      item.classList.remove('active')
    })
    if(displayedCurrency) {
      displayedCurrency.innerHTML = selectedCurrency
    }
  }
}

export function mobileHeader() {
  let elements = document.querySelectorAll(
    '[mobile-menu] li:not(.do-not-trigger)',
  )
  let menu = document.querySelector('.toggle-menu')
  let bodyElem = document.querySelectorAll('html, body')
  let backIcon = document.querySelector('.mobile-back-icon')
  let mobileHeader = document.querySelector('.mobile-header')
  let mobileLogo =
    document.querySelector('.mobile-header .logo') ||
    document.querySelector('.mobile-header .padi-logo')
  let searchPageItems = document.querySelectorAll('.do-not-trigger')
  let activeTab;
  ['click', 'ontouchstart'].forEach(function(evt) {
    elements.forEach(function(elem) {
      elem.addEventListener(evt, clickingCallback, false)
    })
  });

  ['touchend', 'click'].forEach(function(evt) {
    if(backIcon) {
      backIcon.addEventListener(evt, hideMenu, false)
    }
    searchPageItems.forEach(function(item) {
      item.addEventListener(
        evt,
        function() {
          if(activeTab) {
            hideMenu()
          }
        },
        false,
      )
    })
  })

  function hideMenu() {
    activeTab = null
    elements.forEach(function(elem) {
      elem.classList.remove('active')
    })
    menu.style.display = 'none'
    bodyElem.forEach(function(elem) {
      elem.classList.remove('body-hidden')
    })
    backIcon.style.display = 'none'

    setTimeout(function() {
      if(mobileLogo) {
        mobileLogo.style.display = ''
      }
    }, 50)
  }

  function clickingCallback(e) {
    let _this = e.currentTarget
    let menuOffset = getOffset(mobileHeader).top + mobileHeader.clientHeight
    e.preventDefault()
    elements.forEach(function(elem) {
      elem.classList.remove('active')
    })

    document
      .querySelectorAll('.js-header-mobile > div')
      .forEach(function(item) {
        item.style.display = 'none'
      })

    if(activeTab === _this.dataset.item.toString()) {
      hideMenu()

      return true
    }

    if(
      (menu.style.display === 'none' || menu.style.display === '') &&
      !activeTab
    ) {
      _this.classList.add('active')

      bodyElem.forEach(function(elem) {
        elem.classList.add('body-hidden')
      })

      menu.classList.add('active')
      menu.style.display = 'block'
      menu.style.top = `${menuOffset}px`
      if(mobileLogo) {
        mobileLogo.style.display = 'none'
      }
      backIcon.style.display = 'inline-block'
    } else if(menu.style.display && activeTab) {
      _this.classList.add('active')
      menu.style.display = 'block'
      if(mobileLogo) {
        mobileLogo.style.display = 'none'
      }
      backIcon.style.display = 'inline-block'
    }

    activeTab = _this.dataset.item.toString()

    document.querySelector('.js-header-mobile .' + activeTab).style.display =
      'block'

    return false
  }
}

export function trackClickEvents() {
  onClick('[data-track-click]', (elem) => {
    const { eventAction, eventLabel, eventCategory, eventValue } = elem.dataset
    trackClick({
      event: 'interaction',
      eventCategory,
      eventAction,
      customLabel: eventLabel,
      eventValue,
    })
  })
}

export function stickyBottomNavigation() {
  const stickyBottomElement = document.querySelector('[sticky-bottom]')
  if(stickyBottomElement) {
    const trigger = stickyBottomElement.getAttribute('trigger')
    let position = 0
    let triggerElement

    if(trigger) {
      triggerElement = document.querySelector(trigger)

      position = triggerElement.offsetHeight + getOffset(triggerElement).top
    }

    const throttledScroll = throttle(function() {
      if(window.scrollY > position) {
        stickyBottomElement.classList.add('active')
        animate(function() {
          stickyBottomElement.style.bottom = 0
        }, 1000)
      } else if(stickyBottomElement.classList.contains('active')) {
        animate(
          function() {
            stickyBottomElement.style.bottom = '-100px'
          },
          300,
          function() {
            stickyBottomElement.classList.remove('active')
          },
        )
      }
    }, 300)

    window.onscroll = throttledScroll
  }
}

export function stickyNavigation() {
  const elem = document.querySelector('[sticky-top]')
  if(elem) {
    const trigger = elem.getAttribute('trigger')
    const clickable = elem.getAttribute('clickable')

    let position = 0
    let triggerElement

    if(trigger) {
      triggerElement = document.querySelector(trigger)

      position = triggerElement.offsetHeight + getOffset(triggerElement).top
    }

    if(clickable) {
      elem.addEventListener('click', function() {
        if(trigger) {
          window.scrollTo(0, getOffset(triggerElement).top)
        } else {
          window.scrollTo(0, 0)
        }
      })
    }

    const throttledScroll = throttle(function() {
      if(window.scrollY > position) {
        elem.classList.add('active')
      } else if(elem.classList.contains('active')) {
        let margin = -5
        animate(
          function() {
            elem.style.marginTop = margin + 'px'

            margin -= 5
          },
          300,
          function() {
            elem.style.marginTop = ''
            elem.classList.remove('active')
          },
        )
      }
    }, 300)

    window.onscroll = throttledScroll
  }
}

export function wishlistDirective(intl) {
  const popup = document.getElementById('wishlist-popup')
  document
    .getElementById('close-wishlist')
    ?.addEventListener('click', function() {
      popup.style.left = '-9999px'
    })

  onClick('.heart', triggerWishlist)
  onClick('.wishlist-btn', triggerWishlist)
  onClick('.btn-wishlist-mobile', triggerWishlist)

  function triggerWishlist(elem, event) {
    if(elem.dataset.noTrigger) {
      return
    }
    event.stopPropagation()
    event.preventDefault()

    const elemOffset = getOffset(elem)

    if(!window.loggedUser) {
      const wishlistPopupWidth = popup.offsetWidth
      const parentPosition = elemOffset.left
      const topOffset =
        elemOffset.top + popup.clientHeight >=
        window.innerHeight + window.scrollY
          ? elemOffset.top - popup.offsetHeight / 2
          : elemOffset.top

      if(parentPosition - wishlistPopupWidth < 0) {
        popup.style.left =
          elemOffset.left + elem.offsetWidth - wishlistPopupWidth + 'px'
        popup.style.top = topOffset - popup.clientHeight - 20 + 'px'
      } else {
        popup.style.left = elemOffset.left - wishlistPopupWidth - 20 + 'px'
        popup.style.top = topOffset - popup.clientHeight / 2 + 'px'
      }
    } else {
      const { type, shopSlug, inWishlist, wishlistId } = elem.dataset
      let clickType = 'add'

      let data = {
        item_type: type,
        item_slug: shopSlug,
      }
      if(inWishlist) {
        data = wishlistId
        clickType = 'delete'
      }

      if(type === 'Activity') {
        if(clickType === 'add') {
          API('/api/adventure/v1/wishlists/default/activities/add/')
            .post({
              activityId: elem.dataset.activityId,
            })
            .then(function() {
              elem.dataset.inWishlist = true

              elem.classList.add('active')

              if(elem.getAttribute('single-shop')) {
                elem.innerHTML = `<i class="font-icons heart-icon"></i>${intl.formatMessage(
                  messages.removeFromWishlist,
                )}`
              }
            })
        } else {
          API('/api/adventure/v1/wishlists/default/activities/delete/')
            .post({
              activityId: elem.dataset.activityId,
            })
            .then(function(res) {
              elem.dataset.inWishlist = ''

              elem.classList.remove('active')

              if(elem.getAttribute('single-shop')) {
                elem.innerHTML = `<i class="font-icons heart-icon"></i>${intl.formatMessage(
                  messages.addToWishlist,
                )}`
              }
            })
        }
      } else {
        if(clickType === 'add') {
          API('account/wishlist/add/', false, true)
            .post(data)
            .then(function(res) {
              elem.dataset.inWishlist = true
              elem.dataset.wishlistId = res.item.id

              elem.classList.add('active')

              if(elem.getAttribute('single-shop')) {
                elem.innerHTML = `<i class="font-icons heart-icon"></i>${intl.formatMessage(
                  messages.removeFromWishlist,
                )}`
              }
            })
        } else {
          API(`account/wishlist/delete/${data}/`, false, true)
            .delete(data)
            .then(function(res) {
              elem.dataset.inWishlist = ''

              elem.classList.remove('active')

              if(elem.getAttribute('single-shop')) {
                elem.innerHTML = `<i class="font-icons heart-icon"></i>${intl.formatMessage(
                  messages.addToWishlist,
                )}`
              }
            })
        }
      }
    }
  }
}

export function onClick(selector, callback) {
  document.addEventListener('click', function(event) {
    var selectors = document.querySelectorAll(selector)
    var element = event.target
    var index = -1
    if(selectors) {
      while(
        element &&
        (index = Array.prototype.indexOf.call(selectors, element)) === -1
      ) {
        element = element.parentElement
      }

      if(index > -1) {
        callback.call(this, element, event)
      }
    }
  })
}

export function collapseItems(
  itemSelector = '.collapsable',
  triggerSelector = '.collapsable-trigger',
  containerSelector = '.collapsable-content',
  allowDefaultBehaviour,
) {
  const elements = document.querySelectorAll(itemSelector)
  elements.forEach((elem) => {
    let collapsableTrigger = elem.querySelector(triggerSelector)
    let container = elem.querySelector(containerSelector)

    if(elem.getAttribute('expanded')) {
      elem.classList.remove('collapsed')
      DOMAnimations.slideDown(container)
    } else {
      elem.classList.add('collapsed')
      DOMAnimations.slideUp(container)
    }
    collapsableTrigger.addEventListener('click', function(e) {
      if(!allowDefaultBehaviour) {
        e.preventDefault()
      }
      elem.classList.add('collapsed-transition')
      DOMAnimations.slideToggle(container, 500, () => {
        elem.classList.remove('collapsed-transition')
      })
      elem.classList.toggle('collapsed')
    })
  })
}

export function mobileCollapseItems() {
  if(window.innerWidth < 768) {
    collapseItems(
      '.collapsable-mobile',
      '.collapsable-mobile-trigger',
      '.collapsable-mobile-content',
    )
  }
}

export function openToggler() {
  const elements = document.querySelectorAll('[open-toggler]')

  elements.forEach((elem) => {
    let sectionToToggle = document.querySelector(
      elem.getAttribute('open-toggler'),
    )

    elem.addEventListener('click', function(e) {
      elem.classList.toggle('active')
      if(elem.classList.contains('active')) {
        elem.innerText = elem.getAttribute('hide-text')
      } else {
        elem.innerText = elem.getAttribute('read-more-text')
      }
      sectionToToggle.classList.toggle('hidden-elem')
    })
  })
}

export function hideTopBanner() {
  try {
    const isHidden = store.session('hideModal')
    if(isHidden) {
      const flashSale = document.querySelectorAll('.flash-sale')
      flashSale &&
        flashSale.forEach((item) => {
          item.style.display = 'none'
        })
    }

    const menu = document.querySelector('.toggle-menu')
    const mobileHeader = document.querySelector('.mobile-header')
    onClick('.flash-sale .close-icon', function(item) {
      DOMAnimations.slideUp(item.parentElement)
      store.session('hideModal', true)
      setTimeout(() => {
        const menuOffset =
          getOffset(mobileHeader).top + mobileHeader.clientHeight
        menu.style.top = `${menuOffset}px`
      }, 500)
    })
  } catch (e) {
    console.error(e)
  }
}

export function smoothScroll() {
  const header = document.querySelector('.pt-sticky-top')
  let headerHeight = 0

  onClick('[smooth-scroll]', function(item, e) {
    e.preventDefault()
    e.stopPropagation()
    const hrefAttr =
      item.getAttribute('scroll-to') || item.getAttribute('href')
    const clickAttr = item.hasAttribute('emulate-click')
    const toggleElement = item.getAttribute('toggle-element-on-scroll')
    const scrollOffset = item.getAttribute('scroll-offset') || 0

    if(header) {
      headerHeight = header.offsetHeight
    }

    if(hrefAttr) {
      const elementToScroll = document.querySelector(hrefAttr)
      if(elementToScroll) {
        const collapsableContainer = elementToScroll.querySelector(
          '.collapsable.collapsed',
        )
        if(hrefAttr[0] === '#' && !item.getAttribute('notPrevent')) {
          window.location.hash = hrefAttr.substr(1)
        }

        const offset =
          getOffset(elementToScroll).top - headerHeight - scrollOffset
        scrollTo(document.documentElement, offset, 150)

        // works only with collapsable directive;
        if(clickAttr && collapsableContainer) {
          elementToScroll.querySelector('.collapsable-trigger').click()
        }
      }
    }
    if(toggleElement) {
      const elementToClick = document.querySelector(toggleElement)
      if(elementToClick) {
        setTimeout(() => elementToClick.click(), 300)
      }
    }
  })
}

export function removeTargetBlankOnMobile() {
  if(isMobile()) {
    document.addEventListener('DOMContentLoaded', () => {
      document.querySelectorAll('a[target="_blank"]').forEach((element) => {
        if(element.href.indexOf(window.location.origin) !== -1) {
          element.removeAttribute('target')
        }
      })
    })
  }
}

export function changeAffiliateDirective() {
  onClick('.affiliate-row-data', function(item) {
    selectAffiliate(item.dataset.id)
  })
}

export function selectAffiliateLink() {
  onClick('#aff_links a', function(item, e) {
    const user = store.get('user')
    const affiliateID = get(user, 'affiliate.memberNumber')
    let href = new URL(item.href)

    if(affiliateID && href) {
      e.preventDefault()
      href.searchParams.append('affiliate_id', affiliateID)
      item.href = href
      window.location.assign(href)
    }
  })
}

export function copyAffiliateLink() {
  const copyButton = document.querySelector('[copy-affiliate-link]')

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.opacity = 0
    textArea.style.position = 'absolute'
    textArea.style.top = '-999px'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand('copy')
      toast.success(
        <FormattedMessage
          id='link_copied'
          defaultMessage='Link was copied to your clipboard'
        />,
      )
    } catch (err) {}

    document.body.removeChild(textArea)
  }

  function copyTextToClipboard(text) {
    if(!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }
    navigator.clipboard.writeText(text).then(
      function() {
        toast.success(
          <FormattedMessage
            id='link_copied'
            defaultMessage='Link was copied to your clipboard'
          />,
        )
      },
      function(err) {
        console.error('Async: Could not copy text: ', err)
      },
    )
  }

  if(copyButton) {
    copyButton.addEventListener('click', function() {
      const longUrl = generateAffiliateUrl()
      if(isStaff()) {
        copyTextToClipboard(longUrl)
        toast.success(
          <FormattedMessage
            id='staff_affiliate_url_generated'
            defaultMessage='Seems like you are staff, long url was generated for you'
          />,
        )
      } else {
        API('url-shortner/')
          .post({
            longUrl,
          })
          .then((data) => copyTextToClipboard(data.shortUrl))
      }
    })
  }
}

export function shareAffiliate() {
  const shareAffiliateContainer = document.querySelector('share-affiliate')
  const shareAffiliateLink = document.querySelector('[share-affiliate-link]')
  const longUrl = generateAffiliateUrl()

  if(shareAffiliateLink && shareAffiliateContainer) {
    shareAffiliateLink.addEventListener('click', function() {
      shareAffiliateContainer.innerHTML = `
      <div class="share-block placeholder-animation">
        <span class='placeholder-animation'></span>
        <span class='placeholder-animation'></span>
        <span class='placeholder-animation'></span>
        <span class='placeholder-animation'></span>
      </div>`

      if(isStaff()) {
        shareAffiliateContainer.innerHTML = `
        <div class="share-block">
        <a target="_blank" href="https://www.facebook.com/sharer.php?u=${longUrl}"><i class="font-icons fb-icon"></i>Facebook</a>
        <a target="_blank" href="https://twitter.com/intent/tweet?url=${longUrl}"><i class="font-icons twitter-icon"></i>Twitter</a>
        <a target="_blank" href="https://www.pinterest.com/pin/create/button/?url=${longUrl}&media=https%3A//s.diviac.com/travel_dist/images/padi_travel_social_default.jpg&description="><i class="font-icons icon-pinterest"></i>Pinterest</a>
        <a target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=${longUrl}&title=PADI%20Travel&summary=PADI%20Travel&source="><i class="font-icons icon-linkedin"></i>Linkedin</a>
        </div>`
      } else {
        API('url-shortner/')
          .post({
            longUrl,
          })
          .then(function(data) {
            return encodeURIComponent(data.shortUrl)
          })
          .then(function(affiliateLink) {
            shareAffiliateContainer.innerHTML = `<div class="share-block">
        <a target="_blank" href="https://www.facebook.com/sharer.php?u=${affiliateLink}"><i class="font-icons fb-icon"></i>Facebook</a>
        <a target="_blank" href="https://twitter.com/intent/tweet?url=${affiliateLink}"><i class="font-icons twitter-icon"></i>Twitter</a>
        <a target="_blank" href="https://www.pinterest.com/pin/create/button/?url=${affiliateLink}&media=https%3A//s.diviac.com/travel_dist/images/padi_travel_social_default.jpg&description="><i class="font-icons icon-pinterest"></i>Pinterest</a>
        <a target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=${affiliateLink}&title=PADI%20Travel&summary=PADI%20Travel&source="><i class="font-icons icon-linkedin"></i>Linkedin</a>
        </div>`
          })
      }
    })
  }
}

export function abTest(id) {
  if(window.google_optimize && window.google_optimize.get(id) === '1') {
    const textToRemove = document.getElementById('experiment-row')
    if(textToRemove) {
      textToRemove.remove()
    }
  }
}

export function collectionDescriptionMargin() {
  let collecitionDescription = document.getElementById(
    'collection-description',
  )
  if(collecitionDescription) {
    if(window.innerWidth >= 1024) {
      collecitionDescription.style.marginTop = `-${
        collecitionDescription.clientHeight / 2
      }px`
    }
    window.addEventListener('resize', function() {
      if(window.innerWidth < 1024) {
        collecitionDescription.style.marginTop = ''
      } else {
        collecitionDescription.style.marginTop = `-${
          collecitionDescription.clientHeight / 2
        }px`
      }
    })
  }
}

export function toggleDestinations() {
  const destinationsList = [
    ...document.querySelectorAll('.destinations-list__details'),
  ]

  document.addEventListener('click', (e) => {
    let elem = e.target
    let parents = []
    let clickedInside = false
    while(elem) {
      parents.unshift(elem)
      elem = elem.parentNode
    }
    parents.forEach((elem) => {
      if(
        elem.classList &&
        elem.classList.contains('destinations-list__item__title')
      ) {
        clickedInside = true
        const parent = elem.parentElement
        const list = parent.querySelector('.destinations-list__details')
        parent.classList.toggle('active')
        DOMAnimations.slideToggle(list, 300)
        if(parent.classList.contains('active')) {
          destinationsList.forEach((item) => {
            if(item !== list) {
              item.parentElement.classList.remove('active')
              DOMAnimations.slideUp(item, 300)
            }
          })
        }
      }
    })
    if(!clickedInside) {
      destinationsList.forEach((item) => {
        item.parentElement.classList.remove('active')
        DOMAnimations.slideUp(item, 300)
      })
    }
  })
}

export function headerDestinationToggle() {
  onClick('#header_operator_toggler .option', function(item) {
    const toggler = item.parentElement
    const container = item.parentElement.parentElement
    if(!item.classList.contains('active')) {
      toggler.getElementsByClassName('active')[0].classList.toggle('active')
      item.classList.toggle('active')
      container.querySelectorAll('.country-list-wrapper').forEach((item) => {
        item.classList.toggle('active')
      })
    }
  })
}

export function hideMobileAppPromotion(hideOnMobile) {
  try {
    if(hideOnMobile && isMobile()) {
      return true
    }
    const isHidden = store.session('hideMobileAppPopup')
    let popup = document.getElementById('adventures_app_popup')
    if(popup) {
      if(!isHidden) {
        setTimeout(() => {
          popup && popup.classList.add('visible')
        }, 10 * 1000) // 10 sec
      } else {
        popup.remove()
      }
    }
    onClick('.mobile-app-popup .close-btn', function(item) {
      const container = item.parentElement.parentElement
      container.classList.remove('visible')
      window.setTimeout(function() {
        container.remove()
        store.session('hideMobileAppPopup', true)
      }, 600)
    })
  } catch (e) {
    console.error(e)
  }
}

const DOMAnimations = {
  /**
   * SlideUp
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @returns {Promise<boolean>}
   */
  slideUp: function(element, duration = 500, callback) {
    return new Promise(function(resolve, reject) {
      element.style.height = element.offsetHeight + 'px'
      element.style.transitionProperty = `height, margin, padding`
      element.style.transitionDuration = duration + 'ms'
      element.offsetHeight
      element.style.overflow = 'hidden'
      element.style.height = 0
      element.style.paddingTop = 0
      element.style.paddingBottom = 0
      element.style.marginTop = 0
      element.style.marginBottom = 0
      window.setTimeout(function() {
        element.style.display = 'none'
        element.style.removeProperty('height')
        element.style.removeProperty('padding-top')
        element.style.removeProperty('padding-bottom')
        element.style.removeProperty('margin-top')
        element.style.removeProperty('margin-bottom')
        element.style.removeProperty('overflow')
        element.style.removeProperty('transition-duration')
        element.style.removeProperty('transition-property')
        resolve(false)
        if(typeof callback === 'function') {
          callback()
        }
      }, duration)
    })
  },

  /**
   * SlideDown
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @returns {Promise<boolean>}
   */
  slideDown: function(element, duration = 500, callback) {
    return new Promise(function(resolve, reject) {
      element.style.removeProperty('display')
      let display = window.getComputedStyle(element).display

      if(display === 'none') {
        display = 'block'
      }

      element.style.display = display
      let height = element.offsetHeight
      element.style.overflow = 'hidden'
      element.style.height = 0
      element.style.paddingTop = 0
      element.style.paddingBottom = 0
      element.style.marginTop = 0
      element.style.marginBottom = 0
      element.offsetHeight
      element.style.transitionProperty = `height, margin, padding`
      element.style.transitionDuration = duration + 'ms'
      element.style.height = height + 'px'
      element.style.removeProperty('padding-top')
      element.style.removeProperty('padding-bottom')
      element.style.removeProperty('margin-top')
      element.style.removeProperty('margin-bottom')
      window.setTimeout(function() {
        element.style.removeProperty('height')
        element.style.removeProperty('overflow')
        element.style.removeProperty('transition-duration')
        element.style.removeProperty('transition-property')
        if(typeof callback === 'function') {
          callback()
        }
      }, duration)
    })
  },

  /**
   * SlideToggle
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @returns {Promise<boolean>}
   */
  slideToggle: function(element, duration = 500, callback) {
    if(window.getComputedStyle(element).display === 'none') {
      return this.slideDown(element, duration, callback)
    } else {
      return this.slideUp(element, duration, callback)
    }
  },
}

export function diveGuidesHeaderFooter() {
  const toggleElements = (buttons, dropdowns, isSingleActive = false) => {
    buttons.forEach(function(bI, bIndex) {
      if(!bI) {
        return
      }
      return bI.addEventListener('click', function() {
        this.classList.toggle('active')
        dropdowns.forEach(function(mI, mIndex) {
          return bIndex === mIndex
            ? mI.classList.toggle('active')
            : isSingleActive
              ? mI.classList.remove('active')
              : null
        })
      })
    })
  }

  toggleElements(
    document.querySelectorAll('.drop-nav-title'),
    document.querySelectorAll('.drop-content-nav'),
  )

  // top navigation menu
  document.addEventListener('click', ({ target }) => {
    const isTarget = target.classList.contains(
      'topbar-nav-dropdown-menu__link',
    )
    const isNavButton = target.closest('.topbar-nav-list-btn')

    if(isTarget) {
      const dropdown = target.closest('.topbar-nav-dropdown__item')
      dropdown.classList.remove('active')
    } else if(!isNavButton) {
      const dropdowns = document.querySelectorAll('.topbar-nav-dropdown__item')
      dropdowns.forEach((item) => item.classList.remove('active'))
    }
  })

  toggleElements(
    document.querySelectorAll('.topbar-nav-list-btn'),
    document.querySelectorAll('.topbar-nav-dropdown__item'),
    true,
  )

  // toggleElements(document.querySelectorAll('.drop-nav-title'), document.querySelectorAll('.drop-nav-list'));
  let contentNavDropdown = document.querySelectorAll('.content-nav-dropdown')
  contentNavDropdown.forEach((elem) => {
    elem.addEventListener('click', function() {
      elem.classList.remove('active')
      this.classList.add('active')
    })
  })

  toggleElements(
    document.querySelectorAll('.header-mobile-sublist-btn'),
    document.querySelectorAll('.header-mobile-dropdown-menu'),
  )

  toggleElements(
    document.querySelectorAll('.drop-content-nav'),
    document.querySelectorAll('.drop-nav-list'),
  )

  let navbarMobileBtn = document.getElementById('navbarMobileBtn')
  let navbarMobileMenu = document.getElementById('navbarMobileMenu')

  let search = document.getElementById('search')
  let searchCancel = document.getElementById('searchCancel')
  let navbarCollapse = document.getElementById('navbarCollapse')

  if(navbarMobileBtn) {
    navbarMobileBtn.addEventListener('click', function() {
      this.classList.toggle('active')
      navbarMobileMenu.classList.toggle('active')
      navbarCollapse.classList.remove('open')
      DOMAnimations.slideToggle(navbarMobileMenu)
    })
  }

  if(search) {
    search.addEventListener('click', function() {
      navbarCollapse.classList.toggle('open')
      navbarMobileMenu.classList.remove('active')
      navbarMobileBtn.classList.remove('active')
    })
  }

  if(searchCancel) {
    searchCancel.addEventListener('click', function() {
      navbarCollapse.classList.toggle('open')
    })
  }
}

export function openContactCenter(renderElement) {
  const contactBtn = document.getElementById('sf-contact-btn')
  const contactBtnCollection = document.querySelectorAll('.salesforce-contact')
  const shopInfo = window.shopInfo
  const message = window.unableOpenPopup
  const wechatQr = window.wechatQr

  if(contactBtn) {
    contactBtn.addEventListener('click', () => {
      openSalesForcePopup(shopInfo, false, false, message)
    })
  }

  if(contactBtnCollection) {
    contactBtnCollection.forEach((btn) => {
      btn.addEventListener('click', () => {
        if(wechatQr) {
          renderElement('hero-lightbox-qr', PicPreview, {
            photo: wechatQr,
          })
          return
        }
        openSalesForcePopup(shopInfo, false, false, message)
      })
    })
  }
}

export function breadcrumbsToggle() {
  const dots = document.getElementById('dg-breadcrumbs-expand')
  const breadcrubsList = document.getElementById('dg-breadcrumbs')

  const handleClick = () => {
    breadcrubsList.classList.add('xs-expanded')
    dots.removeEventListener('click', handleClick)
  }

  if(dots && breadcrubsList) {
    dots.addEventListener('click', handleClick)
  }
}

export function padiHeaderScripts(onClick) {
  if(!document.getElementById('padi-new-header')) {
    return false
  }
  const body = document.querySelector('body')
  const header = document.querySelector('.padi-nav-header')
  const searchContainer = document.querySelector('#search-container')
  const toggleItems = document.querySelectorAll(
    '.padi-nav-header__list-item-title:not(.prevent-toggle), .padi-nav-header__menu-list-item-title_toggle',
  )

  document
    .querySelector('#toggle-mobile-menu')
    .addEventListener('click', function() {
      setMobileHeaderPosition(header, true)
      if(header.classList.contains('visible-menu')) {
        body.classList.remove('body-hidden')
      } else {
        body.classList.add('body-hidden')
      }
      header.classList.remove('visible-account')
      searchContainer.classList.remove('visible-search')
      header.classList.toggle('visible-menu')
    })
  document
    .querySelector('#toggle-account-menu')
    .addEventListener('click', function() {
      setMobileHeaderPosition(header, true)
      if(header.classList.contains('visible-account')) {
        body.classList.remove('body-hidden')
      } else {
        body.classList.add('body-hidden')
      }
      header.classList.remove('visible-menu')
      searchContainer.classList.remove('visible-search')
      header.classList.toggle('visible-account')
    })
  document
    .querySelector('#toggle-search')
    .addEventListener('click', function() {
      setMobileHeaderPosition(header, true)
      if(header.classList.contains('visible-search')) {
        body.classList.remove('body-hidden')
      } else {
        body.classList.add('body-hidden')
      }
      header.classList.remove('visible-account', 'visible-menu')
      searchContainer.classList.toggle('visible-search')
    })
  toggleItems.forEach(function(item) {
    item.addEventListener('click', function() {
      let isExpanded = false
      if(item.parentElement.classList.contains('expanded')) {
        isExpanded = true
      }
      toggleItems.forEach((elem) =>
        elem.parentElement.classList.remove('expanded'),
      )
      item.parentElement.classList[isExpanded ? 'remove' : 'add']('expanded')
      if(!isExpanded) {
        scrollIfNeeded(
          item,
          document.querySelector('.padi-nav-header__main-nav'),
        )
      }
    })
  })
  setMobileHeaderPosition(header)

  function setMobileHeaderPosition(headerElement, scrollTop) {
    if(scrollTop) {
      window.scrollTo(0, 0)
    }
    const topPosition = headerElement.offsetTop + headerElement.offsetHeight
    const mainNav = document.querySelector('.padi-nav-header__main-nav')
    const mobileNav = document.querySelector(
      '.padi-nav-header__item-mobile-nav',
    )
    mainNav.style.top = `${topPosition}px`
    mobileNav.style.top = `${topPosition}px`
    setPseudoElPosition(topPosition)
  }

  // flickering animation fix
  const navItems = document.querySelectorAll('.padi-nav-header__list-item')
  let hoveredElement = null
  navItems.forEach((item) => {
    item.addEventListener(onClick ? 'click' : 'mouseenter', function() {
      header.classList.add('stop-animation', 'hovered')
      item.classList.add('hovered')
      hoveredElement = item
      document
        .querySelectorAll('.padi-nav-header__list-item')
        .forEach((elem) => {
          if(elem !== item) {
            elem.classList.remove('hovered')
          }
        })
      item
        ?.querySelector('.padi-nav-header__menu-wrapper')
        ?.addEventListener('mouseleave', function() {
          header.classList.remove('stop-animation')
          setTimeout(() => {
            hoveredElement?.classList?.remove('hovered')
            hoveredElement = null
          }, 800)
        })
    })
  })
}

export function translateButtonToggle() {
  const button = document.getElementById('translate-address')
  if(button) {
    button.addEventListener('click', (e) => {
      document.querySelector('.translated-address').classList.toggle('visible')
    })
  }
}

function setPseudoElPosition(value) {
  document.querySelector('head').insertAdjacentHTML(
    'beforeend',
    `<style type="text/css">
  .padi-nav-header.visible-menu:after{top:${value}px}
  .padi-nav-header.visible-account:after{top:${value}px}
  `,
  )
}

function scrollIfNeeded(element, container) {
  if(element.offsetTop < container.scrollTop) {
    container.scrollTop = element.offsetTop
  } else {
    const offsetBottom = element.offsetTop + element.offsetHeight
    const scrollBottom = container.scrollTop + container.offsetHeight
    if(offsetBottom > scrollBottom) {
      container.scrollTop = offsetBottom - container.offsetHeight
    }
  }
}

export function fitDslNav() {
  document.addEventListener(
    'DOMContentLoaded',
    function() {
      setDslNav()
    },
    { once: true },
  )
  window.addEventListener('resize', setDslNav)
}

function setDslNav() {
  if(window.innerWidth < 1024) {
    return false
  }
  setTimeout(() => {
    const nav = document.getElementById('dsl-nav')
    const filters = document.getElementById('dsl-filters')
    const header = document.querySelector('.dsl-header')
    if(nav && filters) {
      nav.style.top = `${header.offsetHeight + filters.offsetHeight}px`
    }
  }, 300)
}

export function hideIntercom() {
  const query = parseQuery() || {}
  const intercomHide =
    query.showIntercom === 'false' || query.widget === 'true'
  setTimeout(() => {
    if(intercomHide && window.Intercom) {
      const chatButton = document.getElementById('chat-buttons')
      if(chatButton) {
        chatButton.style.display = 'none'
      }
      window.Intercom('shutdown')
    }
  }, 1500)
}
