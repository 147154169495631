import get from 'lodash/get'
import { createUrl } from '../../../../localized-urls'
import { dummyImagePath } from 'config'
import PropTypes from 'prop-types'

const TopItemCard = ({
  data,
  regionIdentifier,
}) => (
  <div className='operator-item-wrapper'>
    <a
      className='operator-item-card bordered-element operator-item-card-img'
      href={data.url}
      data-navigation-elements='data-navigation-elements'
      data-region-identifier={regionIdentifier}
    >
      <div className='img-wrapper'>
        <span
          style={{
            backgroundImage: `url(${data.image})`,
          }}
          className='img'
        />
      </div>
      {data.country && (
        <div className='operator-item-card-img-subtitle'>{data.country}</div>
      )}
      <div className='operator-item-card-img-title'>{data.title}</div>
      {/*  Add dangerouslySetInnerHTML because translators want to use hyphen */}
      {data.description && (
        <div className='operator-item-card-img__description'>
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
      )}
    </a>
  </div>
)

export const TopLocationCard = ({
  data,
  regionIdentifier,
}) => {
  const image = get(
    data,
    'images.800x800',
    get(data, 'image.800x800', dummyImagePath),
  )

  const url = createUrl('diving_in', { slug: data.slug })

  const mappedData = {
    ...data,
    image,
    url: data.travel_url || url,
    country: data.parent_title,
  }

  return <TopItemCard data={mappedData} regionIdentifier={regionIdentifier} />
}

export const TopSiteCategoryCard = ({
  data,
  regionIdentifier,
}) => {
  const image = get(
    data,
    'image.500x500',
    get(data, 'image.origin', dummyImagePath),
  )
  const url = createUrl(
    'dive_sites_world',
    {},
    `?dive_site_types=${data.slug}`,
  )

  const mappedData = {
    ...data,
    image,
    url: url || `/dive-sites/world/?dive_site_types=${data.slug}`, // mock till other translations not provided
    description: data.teaser,
  }

  return <TopItemCard data={mappedData} regionIdentifier={regionIdentifier} />
}

export const TopSiteCard = ({
  data,
  regionIdentifier,
}) => {
  const image = get(
    data,
    'images[0]thumbnail.800x800',
    get(data, 'images[0]thumbnail.origin', dummyImagePath),
  )

  const mappedData = {
    ...data,
    image,
    url: data.travel_url,
    country: data.location_title,
  }

  return <TopItemCard data={mappedData} regionIdentifier={regionIdentifier} />
}

TopItemCard.propTypes = {
  data: PropTypes.object,
  regionIdentifier: PropTypes.string,
}

TopLocationCard.propTypes = {
  data: PropTypes.object,
  regionIdentifier: PropTypes.string,
}

TopSiteCategoryCard.propTypes = {
  data: PropTypes.object,
  regionIdentifier: PropTypes.string,
}

TopSiteCard.propTypes = {
  data: PropTypes.object,
  regionIdentifier: PropTypes.string,
}
